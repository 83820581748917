import React, {useEffect, useState} from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Wrapper} from './FormBlock.styles'
import GeneralContacts from '../GeneralContacts'
import Categories from '../Categories'
import FormAssessment from '../FormAssessment'
import FormSubscribe from '../FormSubscribe'
import Share from '../Share'
import {graphql} from "gatsby";

const FormBlock = ({children, location, categoryData, bodyHeight, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  const lawyersPaths = []
  const servicesPaths = []
  const blogsPaths = ['/blogs/', '/blogs', `/${language}/blogs`, `/${language}/blogs/`]
  const categoriesServicePaths = []
  const categoriesAuthorPaths = []
  const blogPaths = []

  categoryData.allStrapiLawyer.edges.map(item =>
    lawyersPaths.push(`/lawyers/${item.node.page_url}`)
  )

  categoryData.allStrapiLawyer.edges.map(item =>
    lawyersPaths.push(`/en/lawyers/${item.node.page_url}`)
  )

  categoryData.allStrapiService.edges.map(item =>
    servicesPaths.push(`/en/services/${item.node.page_url}`)
  )

  categoryData.allStrapiService.edges.map(item =>
    servicesPaths.push(`/services/${item.node.page_url}`)
  )

  categoryData.allStrapiService.edges.map(item =>
    categoriesServicePaths.push(`/category-service/${item.node.page_url}`)
  )

  categoryData.allStrapiService.edges.map(item =>
    categoriesServicePaths.push(`/en/category-service/${item.node.page_url}`)
  )

  categoryData.allStrapiLawyer.edges.map(item =>
    categoriesAuthorPaths.push(`/category-author/${item.node.page_url}`)
  )

  categoryData.allStrapiLawyer.edges.map(item =>
    categoriesAuthorPaths.push(`/en/category-author/${item.node.page_url}`)
  )

  categoryData.allStrapiBlog.edges.map(item => blogPaths.push(`/blogs/${item.node.page_url}`))

  categoryData.allStrapiBlog.edges.map(item => blogPaths.push(`/en/blogs/${item.node.page_url}`))

  const isMatch = path => path === location.pathname

  const isMatchLawyersPaths = () => lawyersPaths.some(isMatch)
  const isMatchServicesPaths = () => servicesPaths.some(isMatch)
  const isMatchBlogsPaths = () => blogsPaths.some(isMatch)
  const isMatchCategoriesServicePaths = () => categoriesServicePaths.some(isMatch)
  const isMatchCategoriesAuthorPaths = () => categoriesAuthorPaths.some(isMatch)
  const isMatchBlogPaths = () => blogPaths.some(isMatch)

  return (
    <Wrapper {...rest} location={location.pathname} minHeight={bodyHeight}>
      <FormAssessment boxShadow />
      {isMatchBlogsPaths() || isMatchCategoriesServicePaths() || isMatchCategoriesAuthorPaths() ? (
        <Categories categoryData={categoryData} />
      ) : null}
      {isMatchBlogPaths() ||
      isMatchBlogsPaths() ||
      isMatchCategoriesServicePaths() ||
      isMatchCategoriesAuthorPaths() ? (
        <FormSubscribe />
      ) : null}
      {isMatchBlogPaths() ? <Share location={location} /> : null}
      {isMatchServicesPaths() || isMatchLawyersPaths() ? null : <GeneralContacts floating />}
    </Wrapper>
  )
}

// FormBlock.propTypes = {}
// FormBlock.defaultProps = {}

export default FormBlock
