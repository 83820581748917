export const fontSizes = {
  xxs: '0.625rem', // 10px
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  def: '1rem', // 16px
  md: '1.125rem', // 18px
  lg: '1.25rem', // 20px
  titleXs: '1.375rem', // 22px
  titleSm: '1.5rem', // 24px
  title: '1.75rem', // 28px
  titleLg: '2,4375rem', // 39px
}

export const fontFamilies = {
  default: 'RobotoCondensed, sans-serif',
  lato: 'Lato, sans-serif',
}
