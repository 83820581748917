import React, {useCallback, useState} from 'react'
import * as PropTypes from 'prop-types'
import FormAssessmentModal from '../components/FormAssessmentModal'

const AssessmentFormContext = React.createContext({})

const AssessmentFormContextProvider = ({children}) => {
  const [isOpened, setOpened] = useState(false)
  const closeModal = useCallback(() => setOpened(false), [])
  const openModal2 = useCallback(() => setOpened(true), [])

  return (
    <AssessmentFormContext.Provider
      value={{
        isOpened,
        closeModal,
        openModal2,
      }}>
      <FormAssessmentModal isOpened={isOpened} onClose={closeModal} />
      {children}
    </AssessmentFormContext.Provider>
  )
}

AssessmentFormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {AssessmentFormContextProvider}
export default AssessmentFormContext
