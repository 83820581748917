import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Wrapper} from './GeneralContactsMunchenInfo.styles'
import Text from '../Text'
import {ContactLink} from '../GeneralContactsHamburgInfo/GeneralContactsHamburgInfo.styles'

const GeneralContactsMunchenInfo = ({children, ...rest}) => {
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <Text content={t('generalContacts.munchen.title')} mb={16} uppercase fontWeight={700} />
      <Text content={t('generalContacts.company')} mb={10} />
      <Text content={t('generalContacts.munchen.street')} mb={10} />
      <ContactLink href="mailto:info@korten-ag.de">
        {t('generalContacts.hamburg.email')}
      </ContactLink>
      <ContactLink href="tel:+49(0)408221822">{t('generalContacts.hamburg.tel')}</ContactLink>
      <ContactLink href="tel:+49(0)408221823">{t('generalContacts.hamburg.fax')}</ContactLink>
    </Wrapper>
  )
}

GeneralContactsMunchenInfo.propTypes = {}
GeneralContactsMunchenInfo.defaultProps = {}

export default GeneralContactsMunchenInfo
