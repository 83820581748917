import styled from 'styled-components'

export const textSizes = {
  titleXXL: ({theme}) => ({
    ...theme.responsiveProperty({
      cssProperty: 'fontSize',
      min: 3,
      max: 5, // 80px
      breakpoints: [420, 600, 800, 1000, 1200],
    }),
    lineHeight: 1.05,
    fontWeight: 800,
  }),
  titleXL: ({theme}) => ({
    ...theme.responsiveProperty({
      cssProperty: 'fontSize',
      min: 3,
      max: 4, // 64px
      breakpoints: [420, 600, 800, 1000, 1200],
    }),
    lineHeight: 1.05,
    fontWeight: 800,
  }),
  titleL: ({theme}) => ({
    ...theme.responsiveProperty({
      cssProperty: 'fontSize',
      min: 2.2,
      max: 3.125, // 50px
      breakpoints: [420, 600, 800, 1000, 1200],
    }),
    lineHeight: 1.05,
    fontWeight: 700,
  }),
  titleMd: ({theme}) => ({
    ...theme.responsiveProperty({
      cssProperty: 'fontSize',
      min: 2,
      max: 2.875, // 46
      breakpoints: [420, 600, 800, 1000],
    }),
    lineHeight: 1.2,
    fontWeight: 800,
  }),
  title: ({theme}) => ({
    ...theme.responsiveProperty({
      cssProperty: 'fontSize',
      min: 1.375, // 22px
      max: 2.4375, // 39px
      breakpoints: [420, 600, 800],
    }),
    lineHeight: 1.2,
    fontWeight: 700,
  }),
  titleSm: ({theme}) => ({
    ...theme.responsiveProperty({
      cssProperty: 'fontSize',
      min: 1.8125, // 28px
      max: 1.8125, // 28px
      breakpoints: [420, 600, 800],
    }),
    lineHeight: 1.2,
    fontWeight: 700,
  }),
  titleXs: ({theme}) => ({
    ...theme.responsiveProperty({
      cssProperty: 'fontSize',
      min: 1.375, // 22px
      max: 1.375, // 22px
      breakpoints: [420, 600, 800],
    }),
    lineHeight: 1.2,
    fontWeight: 700,
  }),
  md: ({theme}) => ({
    fontSize: theme.fontSizes.md,
  }),
  def: ({theme}) => ({
    fontSize: theme.fontSizes.def,
  }),
  sm: ({theme}) => ({
    fontSize: theme.fontSizes.sm,
  }),
  xs: ({theme}) => ({
    fontSize: theme.fontSizes.xs,
  }),
  xxs: ({theme}) => ({
    fontSize: theme.fontSizes.xxs,
  }),
}

export const TextStyled = styled.p(
  ({
    theme,
    color,
    fontWeight,
    textAlign,
    uppercase,
    size,
    mb,
    mt,
    maxWidth,
    fontLato,
    underlined,
  }) => ({
    maxWidth,
    display: 'flex',
    alignItems: 'center',
    color: color ? theme.resolveColor(color, true) : 'inherit',
    marginBottom: mb,
    marginTop: mt,
    fontFamily: fontLato ? theme.fontFamilies.lato : theme.fontFamilies.default,
    fontWeight,
    textAlign,
    textDecoration: underlined ? 'underline' : 'none',
    textTransform: uppercase ? 'uppercase' : 'none',
    ...textSizes[size]({theme}),
  })
)
