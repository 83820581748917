import styled from 'styled-components'
import IconSvg from 'images/icons/chevron-down-black.inline.svg'
import {Link} from 'gatsby'
import {collapseWidth} from '../Header/Header.styles'

export const Wrapper = styled(Link)(({theme, bordernone, posrel, mb0}) => ({
  display: posrel ? 'flex' : null,
  flexDirection: posrel ? 'column' : null,

  [theme.breakpoints.down(`${collapseWidth}`)]: {
    width: '100%',
    marginBottom: mb0 ? 0 : 17,
    paddingBottom: 18,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${theme.palette.grey[800]}`,

    '&:last-of-type': {
      pointerEvents: 'none',
      borderBottom: bordernone
        ? `1px solid ${theme.palette.grey[800]}`
        : `1px solid ${theme.palette.grey[1000]}`,
    },
  },
}))

export const Title = styled.div(({theme, bordernone, posrel, itemLink}) => ({
  position: 'relative',
  height: '100%',
  paddingLeft: posrel ? 26 : null,
  pointerEvents: bordernone ? 'all' : 'none',

  [theme.breakpoints.down(`${collapseWidth}`)]: {
    width: itemLink ? '100%' : 'auto',
    textAlign: 'left',
  },
}))

export const ItemBorder = styled.div(({theme, minWidth}) => ({
  padding: '20px 34px 15px 15px',
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: '-20px',
  top: '50%',
  transform: 'translateY(-50%)',
  minWidth,
  border: `2px solid ${theme.palette.grey[1000]}`,
}))

export const DropdownIcon = styled(IconSvg)(({theme, posrel}) => ({
  position: 'absolute',
  top: '50%',
  right: posrel ? null : '-18px',
  left: posrel ? 0 : null,
  transform: 'translateY(-50%)',
  width: '8px',
  height: '5px',
}))
