import React, {useState} from 'react'
// import PropTypes from 'prop-types'
import * as yup from 'yup'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {Wrapper, ErrorWrapper} from './FormSubscribe.styles'
import Input from '../Input'
import Text from '../Text'
import Button from '../Button'

const FormSubscribe = ({children, ...rest}) => {
  const {t} = useTranslation()
  const [resultState, setResultState] = useState('')
  const [resultMessage, setResultMessage] = useState('')

  const schema = yup.object().shape({
    EMAIL: yup.string().email().required(),
    agree: yup.bool().oneOf([true], t('forms.subscribe.errors.emptyCheckboxMessage')),
  })

  const {register, handleSubmit, errors} = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async data => {
    const result = await addToMailchimp(
      data.EMAIL,
      {},
      'https://korten-ag.us19.list-manage.com/subscribe/post?u=3cb2d1c209ebe7b53034635d8&amp;id=564bd93d1b'
    )

    setResultState(result.result)
    setResultMessage(result.msg)
  }
  return (
    <Wrapper {...rest}>
      <Text
        content={t('forms.subscribe.title')}
        size="md"
        mb={20}
        fontWeight={700}
        uppercase
        textAlign="center"
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form">
        <Input
          type="email"
          name="EMAIL"
          id="mce-EMAIL"
          label={t('forms.subscribe.labels.email')}
          reference={register({required: true})}
          uppercase
        />
        {errors.EMAIL && (
          <Text content={t('forms.incorrectEmailMessage')} size="xs" color="error" mb={10} />
        )}
        <Input
          type="checkbox"
          name="agree"
          reference={register({required: true})}
          label={t('forms.subscribe.labels.checkbox')}
          checkbox
          uppercase
          flexStart
          mt={7}
        />
        <Text content={errors.agree?.message} size="xs" color="error" mb={10} />
        <Button
          text={t('forms.subscribe.btnSubmit')}
          w100
          variant="green"
          uppercase
          type="submit"
          id="mc-embedded-subscribe"
          name="subscribe"
        />

        {/* eslint-disable-next-line no-nested-ternary */}
        {resultState === 'success' ? (
          <Text content={t('forms.subscribe.successMessage')} mt={10} color="primary.green" />
        ) : resultState === 'error' ? (
          <ErrorWrapper>
            <Text content={t('forms.subscribe.errorMessage')} mt={10} color="error" />
          </ErrorWrapper>
        ) : (
          ''
        )}

        {/* mailchimp stuff starts */}
        {/* //// */}
        {/* //// */}
        <div id="mce-responses" className="clear">
          <div className="response" id="mce-error-response" style={{display: 'none'}} />
          <div className="response" id="mce-success-response" style={{display: 'none'}} />
        </div>
        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
          <input type="text" name="b_3cb2d1c209ebe7b53034635d8_03016d9665" tabIndex="-1" />
        </div>
        {/* mailchimp stuff ends */}
        {/* //// */}
        {/* //// */}
      </form>
    </Wrapper>
  )
}

FormSubscribe.propTypes = {}
FormSubscribe.defaultProps = {}

export default FormSubscribe
