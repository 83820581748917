import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  max-width: max-content;

  &:focus {
    outline: none;
  }
`

export const BurgerLine = styled.div`
  width: 22px;
  height: 2px;
  background: ${({theme}) => theme.palette.primary.main};
  transition: all 150ms linear;
  position: relative;
  border-radius: 50rem;
  transform-origin: 1px;

  :first-child {
    transform: ${({opened}) => (opened ? 'rotate(45deg)' : 'rotate(0)')};
  }

  :nth-child(2) {
    margin: 5px 0;
    align-self: flex-end;
    width: 18px;
    opacity: ${({opened}) => (opened ? '0' : '1')};
    transform: ${({opened}) => (opened ? 'translateX(20px)' : 'translateX(0)')};
  }

  :nth-child(3) {
    transform: ${({opened}) => (opened ? 'rotate(-45deg)' : 'rotate(0)')};
  }
`
