import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Invisible, Item, ItemArrow, ItemWrapper, Wrapper} from './DropdownMenu.styles'
import HeaderContext from '../Header/HeaderContext'

const DropdownMenu = ({data, services, minWidth, posrel, ...rest}) => {
  const {opened, close} = useContext(HeaderContext)
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  const servicesStartPath = `${language !== 'de' ? `/${language}` : ''}/services`
  const lawyersStartPath = `${language !== 'de' ? `/${language}` : ''}/lawyers`
  const clientStartPath = `${language !== 'de' ? `/${language}` : ''}`
  return (
    <Wrapper {...rest} posrel={posrel} minWidth={minWidth}>
      <Invisible />
      {!posrel && services && services !== 'client' && (
        <ItemWrapper>
          <ItemArrow />
          <Item to={servicesStartPath} onClick={close}>
            {t('navigation.services.allServices')}
          </Item>
        </ItemWrapper>
      )}
      {!posrel && !services && services !== 'client' && (
        <ItemWrapper>
          <ItemArrow />
          <Item to={lawyersStartPath} onClick={close}>
            {t('navigation.lawyers.allLawyers')}
          </Item>
        </ItemWrapper>
      )}
      {!posrel &&
        data.map(item => (
          <ItemWrapper key={item.node.page_url}>
            <ItemArrow />
            {services && services !== 'client' && (
              <Item to={`${servicesStartPath}/${item.node.page_url}`} onClick={close}>
                {item.node.name[language]}
              </Item>
            )}
            {services === 'client' && (
              <Item to={`${clientStartPath}${item.node.page_url}`} onClick={close}>
                {t(item.node.name)}
              </Item>
            )}
            {!services && services !== 'client' && (
              <Item to={`${lawyersStartPath}/${item.node.page_url}`} onClick={close}>
                {item.node.name}
              </Item>
            )}
          </ItemWrapper>
        ))}

      {posrel &&
        data.map(({text, to}) => (
          <ItemWrapper key={text}>
            <ItemArrow />{' '}
            <Item to={to} onClick={close}>
              {' '}
              {text}
            </Item>
          </ItemWrapper>
        ))}
    </Wrapper>
  )
}

DropdownMenu.propTypes = {
  data: PropTypes.array,
  minWidth: PropTypes.number,
  posrel: PropTypes.bool,
}
DropdownMenu.defaultProps = {}

export default DropdownMenu
