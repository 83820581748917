import React from 'react'
// import PropTypes from 'prop-types'
import {graphql, useStaticQuery} from 'gatsby'
import {Wrapper, QuotesIcon, SkypeIcon, TeamsIcon, ZoomIcon} from './VideoConferenceIcons.styles'

const VideoConferenceIcons = ({children, ...rest}) => {
  const {img1, img2, img3, img4} = useStaticQuery(graphql`
    query {
      img1: file(relativePath: {eq: "icons/general-contacts/skype.png"}) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      img2: file(relativePath: {eq: "icons/general-contacts/quotes.png"}) {
        childImageSharp {
          fixed(width: 23) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      img3: file(relativePath: {eq: "icons/general-contacts/zoom.png"}) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      img4: file(relativePath: {eq: "icons/general-contacts/teams.png"}) {
        childImageSharp {
          fixed(width: 29) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Wrapper {...rest}>
      <SkypeIcon src={img1} fixed={img1.childImageSharp.fixed} />
      <QuotesIcon src={img2} fixed={img2.childImageSharp.fixed} />
      <ZoomIcon src={img3} fixed={img3.childImageSharp.fixed} />
      <TeamsIcon src={img4} fixed={img4.childImageSharp.fixed} />
    </Wrapper>
  )
}

VideoConferenceIcons.propTypes = {}
VideoConferenceIcons.defaultProps = {}

export default VideoConferenceIcons
