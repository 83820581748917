import React, {useContext, useState} from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import Modal from 'react-modal'
import {Phone, Wrapper, Item, Mail} from './BottomPopupBlock.styles'
import Text from '../Text'
import useWindowSize from '../../hooks/helpers/useWindowSize'
import CallContext from '../../contexts/CallContext'
import AssessmentFormContext from '../../contexts/AssessmentFormContext'
import FormAssessment from '../FormAssessment'
import {CloseIcon, ModalBtnClose, ModalWrapper} from '../ModalFull/ModalFull.styles'
import CallBlock from '../CallBlock'

const customStyles = {
  content: {
    width: '100%',
    margin: '0 auto',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

Modal.setAppElement('#___gatsby')

const BottomPopupBlock = ({children, ...rest}) => {
  const {t} = useTranslation()
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalOpen, setOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }
  const openModal2 = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  const closeModal2 = () => {
    setOpen(false)
  }

  return (
    <Wrapper {...rest}>
      <Item onClick={openModal2}>
        <Phone />
        <Text content={t('bottomPopupBlock.phoneText')} size="sm" mb={0} />
      </Item>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal2}
        style={customStyles}
        contentLabel="Modal">
        <ModalBtnClose onClick={closeModal2}>
          <CloseIcon />
        </ModalBtnClose>
        <CallBlock func={closeModal2} />
      </Modal>
      <Item onClick={openModal}>
        <Mail />
        <Text content={t('bottomPopupBlock.mailText')} size="sm" mb={0} />
      </Item>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Modal">
        <ModalBtnClose onClick={closeModal}>
          <CloseIcon />
        </ModalBtnClose>
        <FormAssessment modal func={closeModal} />
      </Modal>
    </Wrapper>
  )
}

BottomPopupBlock.propTypes = {}
BottomPopupBlock.defaultProps = {}

export default BottomPopupBlock
