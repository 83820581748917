import styled from 'styled-components'
import phone from 'images/common/call-popup-phone-img.inline.svg'
import mail from 'images/common/call-popup-mail-img.inline.svg'

export const Wrapper = styled.div(({theme}) => ({
  margin: '0 auto',
  maxWidth: 375,
  textAlign: 'left',
}))

export const Inner = styled.div(({theme}) => ({
  padding: '25px 22px',
  background: theme.palette.common.white,
}))

export const CallLink = styled.a(({theme}) => ({}))

export const PhoneImg = styled(phone)(({theme}) => ({
  marginBottom: 15,
  maxWidth: 295,
  width: '100%',
}))

export const MailImg = styled(mail)(({theme}) => ({
  marginBottom: 15,
  maxWidth: 295,
  width: '100%',
}))
