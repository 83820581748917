import React from 'react'
// import PropTypes from 'prop-types'
import {InputStyled, Wrapper, TextAreaStyled} from './Input.styles'
import Text from '../Text'

const Input = ({
  type,
  placeholder,
  name,
  id,
  reference,
  required,
  er,
  label,
  uppercase,
  textarea,
  ...rest
}) => (
  <Wrapper {...rest}>
    {!textarea && (
      <>
        <InputStyled
          type={type}
          placeholder={placeholder}
          name={name}
          id={id}
          ref={reference}
          // required={required}
          {...rest}
        />
        <Text content={label} size="xs" mb={0} mt={5} uppercase={uppercase} />
      </>
    )}
    {textarea && (
      <>
        <TextAreaStyled
          type={type}
          placeholder={placeholder}
          name={name}
          id={id}
          ref={reference}
          // required={required}
          {...rest}
        />
        <Text content={label} size="xs" mb={0} mt={5} uppercase={uppercase} />
      </>
    )}
  </Wrapper>
)

Input.propTypes = {}
Input.defaultProps = {}

export default Input
