import React from 'react'
// import PropTypes from 'prop-types'
import {Wrapper} from './CallModal.styles'
import ModalFull from '../ModalFull'
import CallBlock from '../CallBlock'

const CallModal = ({isOpened, onClose, ...rest}) => (
  <ModalFull isOpened={isOpened} onClose={onClose} {...rest}>
    <CallBlock />
  </ModalFull>
)

CallModal.propTypes = {}
CallModal.defaultProps = {}

export default CallModal
