import React, {useCallback, useState} from 'react'
import * as PropTypes from 'prop-types'
import CallModal from '../components/CallModal'

const CallContext = React.createContext({})

const CallContextProvider = ({children}) => {
  const [isOpened, setOpened] = useState(false)
  const closeModal = useCallback(() => setOpened(false), [])
  const openModal = useCallback(() => setOpened(true), [])

  return (
    <CallContext.Provider
      value={{
        isOpened,
        closeModal,
        openModal,
      }}>
      <CallModal isOpened={isOpened} onClose={closeModal} />
      {children}
    </CallContext.Provider>
  )
}

CallContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export {CallContextProvider}
export default CallContext
