import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {ThemeProvider} from 'styled-components'
import Header from 'components/Header'
import Footer from 'components/Footer'
import FontFaces from 'styles/globalStyles/FontFaces'
import Reset from 'styles/globalStyles/Reset'
import theme from 'styles/theme'
import {SharedStateContextProvider} from 'hooks/helpers/useSharedState'
import {graphql, StaticQuery} from 'gatsby'
import FormBlock from '../FormBlock'
import Trustpilot from '../Trustpilot'
import BottomPopupBlock from '../BottomPopupBlock'
import {CallContextProvider} from '../../contexts/CallContext'
import AssessmentFormContext, {
  AssessmentFormContextProvider,
} from '../../contexts/AssessmentFormContext'
import CookieBar from '../CookieBar'
import SEO from '../SEO'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 300,
    offset: 80,
  })
}

const Layout = ({children, location, dataLocales}) => {
  const [bodyHeight, setBodyHeight] = useState(0)
  useEffect(() => {
    const {height} = document.body.getBoundingClientRect()
    setBodyHeight(height)

    const spans = document.getElementsByTagName('span')

    const spansArr = Array.from(spans)

    const filteredSpans = spansArr.filter(i => i.textContent === 'nofollow')

    filteredSpans.forEach(i => {
      i.previousElementSibling.setAttribute('rel', 'nofollow')
      // eslint-disable-next-line no-param-reassign
      i.style.cssText = 'display: none'
    })
  })

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        />
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="c3d7c6e9-9c74-496f-a55b-ab13e6269782"
          type="text/javascript"
        />
        <link rel="alternate" hrefLang="de" href="https://korten-ag.de/"/>
        <link rel="alternate" hrefLang="en" href="https://korten-ag.de/en/"/>
      </Helmet>
      <SEO />
      <FontFaces />
      <Reset />
      <SharedStateContextProvider>
        {/* or api.registerPhone */}
        <AssessmentFormContextProvider>
          <CallContextProvider>
            <StaticQuery
              query={graphql`
                query LayoutQuery {
                  allStrapiLawyer(sort: {order: ASC, fields: lawyer_order}) {
                    edges {
                      node {
                        name
                        page_url
                        position {
                          de
                          en
                        }
                        services {
                          name {
                            de
                            en
                          }
                        }
                      }
                    }
                  }
                  allStrapiService(sort: {order: ASC, fields: service_order}) {
                    edges {
                      node {
                        name {
                          de
                          en
                        }
                        service_types {
                          de
                          en
                        }
                        page_url
                      }
                    }
                  }
                  allStrapiBlog(sort: {order: DESC, fields: date_for_sort}) {
                    edges {
                      node {
                        article {
                          de
                          en
                        }
                        date {
                          de
                          en
                        }
                        description_short {
                          de
                          en
                        }
                        navigation {
                          de
                          en
                        }
                        reading_time {
                          de
                          en
                        }
                        page_url
                        date_for_sort
                        published_at
                        title {
                          de
                          en
                        }
                      }
                    }
                  }
                }
              `}
              render={data => (
                <FormBlock location={location} categoryData={data} bodyHeight={bodyHeight} />
              )}
            />
            {children}
            <Trustpilot />
            <Footer />
            <BottomPopupBlock />
            {/* <CookieBar /> */}
          </CallContextProvider>
        </AssessmentFormContextProvider>
      </SharedStateContextProvider>
    </ThemeProvider>
  )
}

export default Layout
