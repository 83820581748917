import React, {useCallback, useContext, useEffect, useState, createContext} from 'react'

const SharedStateContext = createContext(null)

/**
 * @param entityName {string} - has to be shallow-comparable, string constants are recommended.
 * @returns {Function} - a function with similar to useState api.
 *
 * This is a simple useful general-purpose hook for containers that need
 * to share a common property without "callback-hell" ideology.
 * once given an entityName constant, this hooks has similar api to React's useState.
 * however, once you update the value with set'Something" callback, it's going to propagate
 * to all components, that are using this hook with identical entityName.
 *
 * Usage example:
 * const [selectedIndex, setSelectedIndex] = useSharedState(sharedStateConstants.currentContributionIndex)(-1);
 */
export default function genSharedUseState(entityName) {
  return function useSharedState(initialValue) {
    // hook can manually trigger rerender whenever useState is utilized, and a setter is called with a new value
    const update = useState(0)[1]
    // therefore, if we call setState() with a random string, it will always trigger rerender
    const triggerRerender = useCallback(() => update(Math.random()), [update])

    /** @type SharedStateStore */
    const store = useContext(SharedStateContext)

    const curValue = store.getValue(entityName)
    useEffect(() => {
      store.addListener(entityName, triggerRerender)
      return () => store.removeListener(entityName, triggerRerender)
    }, [store, triggerRerender])

    const setValue = useCallback(
      newValue => {
        store.onUpdateValue(entityName, newValue)
      },
      [store]
    )

    useEffect(() => {
      if (curValue === undefined) {
        setValue(initialValue)
      }
    }, [curValue, setValue, initialValue])

    return [
      // for a first render, useEffect above
      // will not be yet triggered
      curValue === undefined ? initialValue : curValue,
      setValue,
    ]
  }
}

class SharedStateStore {
  _state = {}

  _listeners = {}

  addListener = (key, l) => {
    if (!this._listeners[key]) {
      this._listeners[key] = []
    }
    this._listeners[key].push(l)
  }

  removeListener = (key, l) => {
    if (!this._listeners[key]) {
      return
    }
    this._listeners[key] = this._listeners[key].filter(cl => cl !== l)

    if (this._listeners[key].length === 0) {
      delete this._listeners[key]
    }
  }

  onUpdateValue = (key, newValue) => {
    this._state[key] = newValue
    if (!this._listeners[key]) {
      return
    }
    this._listeners[key].forEach(l => l(newValue))
  }

  getValue = key => this._state[key]
}

export const SharedStateContextProvider = props => (
  <SharedStateContext.Provider value={new SharedStateStore()} {...props} />
)
