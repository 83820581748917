import React, {useEffect, useRef} from 'react'
import * as PropTypes from 'prop-types'
import {Portal} from 'react-portal'
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import {CSSTransition} from 'react-transition-group'
import {
  ModalWrapper,
  ModalContainerFixed,
  ModalBtnClose,
  duration,
  transitionName,
  CloseIcon,
} from './ModalFull.styles'

const ModalFull = ({isOpened, onClose, children, ...rest}) => {
  const modalRef = useRef(null)
  useEffect(() => {
    if (isOpened) disableBodyScroll(modalRef.current)
    else enableBodyScroll(modalRef.current)
  }, [isOpened])

  // release overflow hidden on unmount
  useEffect(
    () => () => {
      enableBodyScroll(modalRef.current)
    },
    []
  )
  return (
    <Portal>
      <CSSTransition
        in={isOpened}
        classNames={transitionName}
        timeout={duration}
        unmountOnExit
        ref={modalRef}>
        <ModalContainerFixed>
          <ModalWrapper {...rest}>
            <ModalBtnClose onClick={onClose}>
              <CloseIcon />
            </ModalBtnClose>
            {children}
          </ModalWrapper>
        </ModalContainerFixed>
      </CSSTransition>
    </Portal>
  )
}

ModalFull.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
}

ModalFull.defaultProps = {
  isOpened: false,
  onClose: null,
}

export default ModalFull
