import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import Text from 'components/Text'
import Button from 'components/Button'
import {Wrapper, Inner, PhoneImg, MailImg, CallLink} from './CallBlock.styles'
import ContentContainer from '../ContentContainer'

const CallBlock = ({children, func, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <Inner>
          <Text content={t('popups.callBack.title')} mb={33} size="titleXs" uppercase />
          <CallLink href="tel:+49408221822">
            <PhoneImg />
          </CallLink>
          <Text content={t('popups.callBack.text1')} mb={33} />
          <CallLink href="mailto:info@korten-ag.de">
            <MailImg />
          </CallLink>
          <Text content={t('popups.callBack.text2')} mb={33} />
          <Button
            to={`${language !== 'de' ? `/${language}` : ''}/contact`}
            text={t('popups.callBack.btnText')}
            w100
            variant="green"
            uppercase
            onClick={func}
          />
        </Inner>
      </ContentContainer>
    </Wrapper>
  )
}

CallBlock.propTypes = {}
CallBlock.defaultProps = {}

export default CallBlock
