import React from 'react'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import ContentContainer from 'components/ContentContainer'
import Text from 'components/Text'
import {graphql, useStaticQuery} from 'gatsby'
import {
  Wrapper,
  LinksRow,
  Item,
  Block,
  Facebook,
  FacebookLink,
  BlockWrapper,
  DisplayMob,
  DisplayDesktop,
} from './Footer.styles'

const Footer = ({children, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  const {img} = useStaticQuery(graphql`
    query {
      img: file(relativePath: {eq: "common/facebook.png"}) {
        childImageSharp {
          fluid(maxWidth: 38) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Wrapper {...rest}>
      <ContentContainer>
        <LinksRow>
          <BlockWrapper>
            <Block reverse>
              <Text size="sm" content={t('footer.copyright')} />
              <DisplayMob>
                <FacebookLink
                  href="https://www.facebook.com/kortenag"
                  rel="noreferrer noopener"
                  target="_blank">
                  <Facebook src={img} fluid={img.childImageSharp.fluid} />
                </FacebookLink>
              </DisplayMob>
            </Block>
          </BlockWrapper>
          <BlockWrapper>
            <Block>
              <Text size="sm" content={t('footer.address1')} />
              <Text size="sm" content={t('footer.address2')} />
            </Block>
            <Block right mobFlexEnd>
              <Text size="sm" content={t('footer.tel')} />
              <Text size="sm" content={t('footer.fax')} />
            </Block>
          </BlockWrapper>
          <BlockWrapper>
            <Block>
              <Text size="sm" content={t('footer.email')} />
              <Text size="sm" content={t('footer.site')} />
            </Block>
            <Block right mobFlexEnd>
              <Item
                to={`${language !== 'de' ? `/${language}` : ''}/imprint`}>
                {t('footer.imprint')}
              </Item>
              <Item
                to={`${language !== 'de' ? `/${language}` : ''}/privacy-policy`}>
                {t('footer.privacy')}
              </Item>
            </Block>
          </BlockWrapper>
          <DisplayDesktop>
            <FacebookLink
              href="https://www.facebook.com/kortenag"
              rel="noreferrer noopener"
              target="_blank">
              <Facebook src={img} fluid={img.childImageSharp.fluid} />
            </FacebookLink>
          </DisplayDesktop>
        </LinksRow>
      </ContentContainer>
    </Wrapper>
  )
}

export default Footer
