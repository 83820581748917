import styled from 'styled-components'
import IconSvg from 'images/icons/chevron-down.inline.svg'

export const Text = styled.span(() => ({
  marginLeft: '0.5em',
}))

export const ButtonTrigger = styled.div(({theme}) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: '0 0.75em',
  fontWeight: 700,
  maxWidth: 'max-content',
  cursor: 'pointer',
  [theme.breakpoints.down('tabletL')]: {
    padding: '0',
  },
}))

export const ShortCode = styled.div(({theme}) => ({
  color: theme.palette.grey[700],
}))

export const DropdownMenuLangWrapper = styled.div(({theme, minWidth}) => ({
  padding: '17px 20px',
  position: 'absolute',
  minWidth,
  left: -20,
  top: 30,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: theme.palette.common.white,
  boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.0964543)',
  textAlign: 'left',
  fontWeight: '400',

  [theme.breakpoints.down('tabletL')]: {
    padding: '17px 0 0 0',
    position: 'static',
    boxShadow: 'none',
    minWidth: '100%',
  },
}))

export const ItemLang = styled.div(({theme}) => ({}))

export const SelectIcon = styled(IconSvg)(() => ({
  width: '8px',
  height: '5px',
  marginLeft: '8px',
}))
