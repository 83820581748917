import React from 'react'
// import PropTypes from 'prop-types'
import ModalFull from '../ModalFull'
import FormAssessment from '../FormAssessment'

const FormAssessmentModal = ({isOpened, onClose, ...rest}) => (
  <ModalFull isOpened={isOpened} onClose={onClose} {...rest}>
    <FormAssessment mAuto textAlign="left" />
  </ModalFull>
)

FormAssessmentModal.propTypes = {}
FormAssessmentModal.defaultProps = {}

export default FormAssessmentModal
