import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  margin: '0 -15px',
  marginBottom: 20,
  display: 'flex',
  justifyContent: 'space-between',
}))

export const NavItem = styled.div(({theme, active}) => ({
  padding: '0 15px',
  width: '100%',
  height: 47,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  color: active ? theme.palette.common.black : theme.palette.common.white,
  background: active ? theme.palette.common.white : theme.palette.common.black,
  fontWeight: 700,
  textAlign: 'center',
  cursor: 'pointer',
}))
