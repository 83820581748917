import styled from 'styled-components'
import {Link} from 'gatsby'
import React from 'react'
import ContentContainer from 'components/ContentContainer'
import Button from 'components/Button'
import Img from 'gatsby-image'
import Burger from '../Burger'
import {collapseWidth, height, heightCollapsed} from '../Header.styles'
import DropdownItem from '../../DropdownItem'

export const Wrapper = styled.div`
  background: ${({theme}) => theme.palette.common.white};
  width: 100%;
  height: ${height}px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 0;
  @media (max-width: ${collapseWidth}px) {
    height: ${heightCollapsed}px;
  }
`

export const LogoIcon = styled(Img)(({theme}) => ({
  height: 35,
  width: 134,
}))

export const LinksWrapper = styled.div`
  max-width: 870px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
`

export const OtherActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Container = styled(ContentContainer)`
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
`

export const LogoLink = styled(Link)`
  max-width: 135px;
  width: 100%;
  display: flex;
  align-items: center;
`

export const Item = styled(DropdownItem).attrs(() => ({}))`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({theme}) => theme.palette.primary.light};
  font-weight: 700;
  margin-left: 1em;
  transition-duration: 150ms;

  @media (max-width: ${collapseWidth}px) {
    margin-left: 0;
    align-items: flex-start;
  }

  &.item--active {
    color: ${({theme}) => theme.palette.primary.main};
    font-weight: 800;
  }
  &:hover {
    color: ${({theme}) => theme.palette.primary.main};
  }
`

export const ButtonAction = styled(props => <Button size="sm" minWidth={120} {...props} />)({
  marginLeft: '1em',
})

export const BurgerStyled = styled(Burger)({
  padding: 5,
})
