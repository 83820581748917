import React from 'react'
import * as PropTypes from 'prop-types'
import {Link} from 'gatsby'
import LoadingCircle from 'components/LoadingCircle'
import {normalizePath} from 'utils'
import {
  btnSizes,
  btnVariants,
  ButtonStyled,
  ContentLoadingWrapper,
  IconStartWrapper,
  IconEndWrapper,
} from './Button.styles'

const Button = ({
  children,
  text,
  to,
  href,
  isLoading,
  disabled,
  size,
  variant,
  startIcon,
  endIcon,
  ...other
}) => {
  const props = {
    ...other,
    size,
    variant,
    isLoading,
    disabled: disabled || isLoading,
  }
  let inner = (
    <>
      {startIcon && <IconStartWrapper>{startIcon}</IconStartWrapper>}
      {text || children}
      {endIcon && <IconEndWrapper>{endIcon}</IconEndWrapper>}
    </>
  )
  if (isLoading)
    inner = (
      <>
        <LoadingCircle thickness={4} size={25} isAbsolute />
        <ContentLoadingWrapper>{inner}</ContentLoadingWrapper>
      </>
    )

  if (to)
    return (
      <ButtonStyled component={Link} to={normalizePath(to)} {...props}>
        {inner}
      </ButtonStyled>
    )
  if (href)
    return (
      <ButtonStyled component="a" href={href} {...props}>
        {inner}
      </ButtonStyled>
    )
  return <ButtonStyled {...props}>{inner}</ButtonStyled>
}

Button.propTypes = {
  size: PropTypes.oneOf(Object.keys(btnSizes)),
  variant: PropTypes.oneOf(Object.keys(btnVariants)),
  w100: PropTypes.bool,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  href: PropTypes.string,
  text: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
}

Button.defaultProps = {
  size: 'def',
  variant: 'black',
  w100: false,
  isLoading: false,
  disabled: false,
}

export default Button
