import React, {useState} from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import placeholder from 'lodash/fp/placeholder'
import {Wrapper, TextWrapper, Inner, ButtonsWrapper, ButtonWrapper} from './CookieBar.styles'
import Text from '../Text'
import ContentContainer from '../ContentContainer'
import Button from '../Button'

// eslint-disable-next-line consistent-return
const CookieBar = ({children, ...rest}) => {
  const [showCookieBar, setShowCookieBar] = useState(true)
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()

  const acceptHandler = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('cookiesAccepted', '1')
      setShowCookieBar(false)
    }
  }

  // eslint-disable-next-line consistent-return
  const isAcceptedCookieBar = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('cookiesAccepted') === '1'
    }
  }

  return (
    <>
      {!isAcceptedCookieBar() && showCookieBar && (
        <Wrapper
          {...rest}
          isAcceptedCookieBar={isAcceptedCookieBar()}
          showCookieBar={showCookieBar}>
          <ContentContainer>
            <Inner>
              <TextWrapper>
                <Text content={t('cookieBar.text')} mb={0} />
              </TextWrapper>
              <ButtonsWrapper>
                <ButtonWrapper>
                  <Button
                    text={t('cookieBar.textMore')}
                    variant="blackOutline"
                    uppercase
                    w100
                    size="sm"
                    to={language !== 'de' ? `/${language}/privacy-policy` : `/privacy-policy`}
                  />
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button
                    onClick={acceptHandler}
                    text={t('cookieBar.textAgree')}
                    variant="green"
                    uppercase
                    w100
                    size="sm"
                  />
                </ButtonWrapper>
              </ButtonsWrapper>
            </Inner>
          </ContentContainer>
        </Wrapper>
      )}
    </>
  )
}

CookieBar.propTypes = {}
CookieBar.defaultProps = {}

export default CookieBar
