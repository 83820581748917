import styled from 'styled-components'
import Img from 'gatsby-image'

export const Wrapper = styled.div(({theme}) => ({
  display: 'flex',
}))

export const SkypeIcon = styled(Img)(({theme}) => ({
  marginRight: 14,
}))

export const QuotesIcon = styled(Img)(({theme}) => ({
  marginRight: 14,
}))

export const ZoomIcon = styled(Img)(({theme}) => ({
  marginRight: 14,
}))

export const TeamsIcon = styled(Img)(({theme}) => ({
  marginRight: 14,
}))
