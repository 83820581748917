import styled from 'styled-components'
import ContentContainer from 'components/ContentContainer'

export const Wrapper = styled(ContentContainer)(({theme, location, minHeight}) => ({
  position: 'absolute',
  top: (location === '/' || location === '/en/' || location === '/en') ? 290 : 120,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 3,
  pointerEvents: 'none',
  minHeight:
    location === '/' || location === '/en/'
      ? minHeight - (290 + 178 + 120)
      : minHeight - (120 + 178 + 120),

  [theme.breakpoints.down('laptopS')]: {
    display: 'none',
  },
}))
