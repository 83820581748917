import {createGlobalStyle} from 'styled-components'
import fontFace from '../utils/fontFace'
import LatoRegular from '../fontFiles/Lato/Lato-Regular.ttf'
import LatoLight from '../fontFiles/Lato/Lato-Light.ttf'
import LatoBold from '../fontFiles/Lato/Lato-Bold.ttf'
import RobotoCondensedBold from '../fontFiles/RobotoCondensed/RobotoCondensed-Bold.ttf'
import LatoBlack from '../fontFiles/Lato/Lato-Black.ttf'
import RobotoCondensedRegular from '../fontFiles/RobotoCondensed/RobotoCondensed-Regular.ttf'

export default createGlobalStyle`
  ${fontFace('Lato', LatoLight, 300)}
  ${fontFace('RobotoCondensed', RobotoCondensedRegular, 400)}
  ${fontFace('Lato', LatoRegular, 400)}
  ${fontFace('Lato', LatoBold, 700)}
  ${fontFace('RobotoCondensed', RobotoCondensedBold, 700)}
  ${fontFace('Lato', LatoBlack, 800)}
`
