import React from 'react'
import PropTypes from 'prop-types'
import {Container, BurgerLine} from './Burger.styles'

const Burger = ({opened, ...rest}) => (
  <Container {...rest}>
    <BurgerLine opened={opened} />
    <BurgerLine opened={opened} />
    <BurgerLine opened={opened} />
  </Container>
)

Burger.propTypes = {
  opened: PropTypes.bool,
  onClick: PropTypes.func,
}
Burger.defaultProps = {
  opened: false,
  onClick: undefined,
}

export default Burger
