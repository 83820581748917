import styled from 'styled-components'

export const Wrapper = styled.div(({theme, isAcceptedCookieBar, showCookieBar}) => ({
  display: !isAcceptedCookieBar && showCookieBar ? 'block' : 'none',
  width: '100%',
  padding: '9px 0',
  position: 'fixed',
  bottom: 0,
  background: theme.palette.primary.bg,
  zIndex: 99999,
  opacity: !isAcceptedCookieBar && showCookieBar ? 1 : 0,
}))

export const Inner = styled.div(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const TextWrapper = styled.div(({theme}) => ({
  marginRight: 15,
  maxWidth: 670,
  width: '100%',

  [theme.breakpoints.down('tablet')]: {
    marginBottom: 20,
  },
}))

export const ButtonsWrapper = styled.div(({theme}) => ({
  maxWidth: 425,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('tablet')]: {
    maxWidth: '100%',
  },
}))

export const ButtonWrapper = styled.div(({theme}) => ({
  maxWidth: 200,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',

  '&:first-of-type': {
    marginRight: 10,
  },

  [theme.breakpoints.down('tablet')]: {
    maxWidth: 150,
  },
}))
