import styled from 'styled-components'

export const Wrapper = styled.div(({theme, mb0, floating}) => ({
  padding: '0 15px 25px 15px',
  boxShadow: `0px 2px 15px rgba(0, 0, 0, 0.196487)`,
  marginLeft: 'auto',
  marginBottom: mb0 ? 0 : 30,
  maxWidth: 370,
  width: '100%',
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.common.black}`,
  pointerEvents: 'all',
  position: floating ? 'sticky' : null,
  top: floating ? 120 : null,

  [theme.breakpoints.down('laptopS')]: {
    marginLeft: 15,
    maxWidth: 375,
  },

  [theme.breakpoints.down('mobileL')]: {
    marginLeft: 0,
  },
}))

export const ContactsInfoWrapper = styled.div(({theme}) => ({
  marginBottom: 24,
  minHeight: 168,
}))
