import React, {useContext} from 'react'
import {CSSTransition} from 'react-transition-group'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql, useStaticQuery} from 'gatsby'
import {
  MobileNavigationLinks,
  MobileNavigationWrapper,
  MobNaVDarkBg,
  TopRow,
  CloseIcon,
  CloseButton,
  LangItemMob,
  LangItemsMob,
} from './SideBar.styles'
import HeaderContext from '../HeaderContext'
import {Item, LogoIcon, LogoLink} from '../TopBar/TopBar.styles'
import {languageMap} from '../../LanguagesDropdown/LanguagesDropdown.config'
import {Text} from '../../LanguagesDropdown/LanguagesDropdown.styles'

const MobNav = ({headerData}) => {
  const {opened, close} = useContext(HeaderContext)
  const {language, languages, changeLanguage} = useI18next()
  const {img} = useStaticQuery(graphql`
    query {
      img: file(relativePath: {eq: "common/logo1.png"}) {
        childImageSharp {
          fluid(maxWidth: 135) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <CSSTransition in={opened} classNames="slide" timeout={300} unmountOnExit>
        <MobileNavigationWrapper>
          <TopRow>
            <LogoLink to={language !== 'de' ? `/${language}/` : '/'} aria-label="Korten Logo">
              <LogoIcon src={img} fluid={img.childImageSharp.fluid} />
            </LogoLink>
            <LangItemsMob>
              {languages.map(l => (
                <LangItemMob
                  key={l}
                  onClick={() => {
                    changeLanguage(l)
                  }}
                  selected={l === language}>
                  <Text>{languageMap[l].textShort}</Text>
                </LangItemMob>
              ))}
            </LangItemsMob>
            <CloseButton onClick={close}>
              <CloseIcon />
            </CloseButton>
          </TopRow>
          <MobileNavigationLinks>
            {headerData.map(
              ({item, subItems, itemLink, type, minWidth, mobOpened, bordernone, services}) => (
                <Item
                  services={services}
                  key={item}
                  data={subItems}
                  title={item}
                  itemLink={itemLink}
                  type={type}
                  minWidth={minWidth}
                  mobOpened={mobOpened}
                  bordernone={bordernone}
                />
              )
            )}
          </MobileNavigationLinks>
        </MobileNavigationWrapper>
      </CSSTransition>
      <CSSTransition in={opened} classNames="fade" timeout={300} unmountOnExit>
        <MobNaVDarkBg onClick={close} />
      </CSSTransition>
    </>
  )
}

export default MobNav
