import React from 'react'
import PropTypes from 'prop-types'
import {textSizes, TextStyled} from './Text.styles'

const Text = ({children, content, component, ...rest}) => (
  <TextStyled as={component} {...rest}>
    {children || content}
  </TextStyled>
)

Text.propTypes = {
  size: PropTypes.oneOf(Object.keys(textSizes)),
  content: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  color: PropTypes.string,
  fontWeight: PropTypes.number,
  textAlign: PropTypes.string,
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
Text.defaultProps = {
  size: 'def',
  component: 'p',
  mb: '0.5em',
}

export default Text
