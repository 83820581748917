import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {graphql} from 'gatsby'
import {Wrapper, Title, Item} from './Categories.styles'

const Categories = ({children, categoryData, ...rest}) => {
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()
  const authorsArr = []
  const servicesArr = []

  function collectAuthors() {
    categoryData.allStrapiLawyer.edges.map(item => {
      const Author = {}
      Author.text = item.node.name
      Author.to = `${language !== 'de' ? `/${language}` : ''}/category-author/${item.node.page_url}`
      return authorsArr.push(Author)
    })
  }
  function collectServices() {
    categoryData.allStrapiService.edges.map(item => {
      const Service = {}
      Service.text = item.node.name[language]
      Service.to = `${language !== 'de' ? `/${language}` : ''}/category-service/${
        item.node.page_url
      }`
      return servicesArr.push(Service)
    })
  }
  collectAuthors()
  collectServices()

  return (
    <Wrapper {...rest}>
      <Title size="md" content={t('categories.title')} />
      <Item title={t('categories.authors')} type="div" data={authorsArr} posrel bordernone mb0/>
      <Item title={t('categories.services')} type="div" data={servicesArr} posrel bordernone />
    </Wrapper>
  )
}

// Categories.propTypes = {}
// Categories.defaultProps = {}

export default Categories
