import styled from 'styled-components'
import CloseIconSvg from 'images/icons/close-icon.inline.svg'

export const duration = 250
export const transitionName = `modal-animation`

export const ModalContainerFixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`

export const ModalWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: ${({theme}) => theme.palette.common.white};
  -webkit-overflow-scrolling: touch;

  .${transitionName}-enter & {
    opacity: 0;
    transform: scale(1.05);
  }

  .${transitionName}-enter-active & {
    opacity: 1;
    transform: scale(1);
    transition: opacity ${duration}ms ease-out, transform ${duration}ms ease-out;
  }

  .${transitionName}-exit & {
    opacity: 1;
    transform: scale(1);
  }

  .${transitionName}-exit-active & {
    opacity: 0;
    transform: scale(1.05);
    transition: opacity ${duration}ms ease-out, transform ${duration}ms ease-out;
  }
`

export const ModalBtnClose = styled.button`
  position: fixed;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
  padding: 10px;
  outline: none;
  cursor: pointer;
  transition-duration: 150ms;

  &:hover {
    transform: scale(1.15);
  }

  ${({theme}) => theme.breakpoints.down('mobileL')} {
    top: 5px;
    right: 5px;
  }
`
export const CloseIcon = styled(CloseIconSvg)`
  fill: currentColor;
  width: 20px;
  height: 20px;
`
