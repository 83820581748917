import React, {useEffect, useState, createContext} from 'react'
import {useTheme} from 'styled-components'
import {useMediaQuery} from '@material-ui/core'
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import {headerNavigation} from 'data/headerNavigation'

const HeaderContext = createContext({})

const HeaderContextProvider = ({children, collapseWidth, navRef}) => {
  const [opened, setOpen] = useState(false)
  const theme = useTheme()
  const isCollapsed = useMediaQuery(theme.breakpoints.down(collapseWidth))

  useEffect(() => {
    if (opened) {
      if (!isCollapsed) {
        setOpen(false)
      } else {
        disableBodyScroll(navRef.current)
      }
    } else {
      enableBodyScroll(navRef.current)
    }
  }, [opened, isCollapsed, navRef])

  // release overflow hidden on unmount
  useEffect(
    () => () => {
      enableBodyScroll(navRef.current)
    },
    [navRef]
  )
  return (
    <HeaderContext.Provider
      value={{
        opened,
        setOpen,
        close: () => setOpen(false),
        toggle: () => setOpen(oldValue => !oldValue),
        collapseWidth,
        isCollapsed,
        navItems: headerNavigation,
      }}>
      {children}
    </HeaderContext.Provider>
  )
}

export {HeaderContextProvider}
export default HeaderContext
