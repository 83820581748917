import styled from 'styled-components'
import phone from 'images/icons/phone-icon.inline.svg'
import mail from 'images/icons/mail-icon.inline.svg'

export const Wrapper = styled.div(({theme}) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  zIndex: 99999,
  padding: '13px 25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.primary.bg,

  [theme.breakpoints.up('laptopS')]: {
    display: 'none',
  },

  [theme.breakpoints.down('mobileL')]: {
    padding: '13px 15px',
  },
}))

export const Item = styled.div(({theme}) => ({
  maxWidth: 140,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  '&:first-of-type': {
    marginRight: 55,
  },
}))

export const Phone = styled(phone)(({theme}) => ({
  marginRight: 12,
  flex: '0 0 10px',
}))

export const Mail = styled(mail)(({theme}) => ({
  marginRight: 12,
  flex: '0 0 20px',
}))
