import React, {useContext} from 'react'
import LanguagesDropdown from 'components/LanguagesDropdown'
import {graphql, useStaticQuery} from 'gatsby'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {
  Container,
  Item,
  LinksWrapper,
  LogoLink,
  OtherActionsWrapper,
  Wrapper,
  LogoIcon,
  BurgerStyled,
} from './TopBar.styles'
import HeaderContext from '../HeaderContext'
// import headerData from '../Header.data'
import {LangItemMob, LangItemsMob} from '../SideBar/SideBar.styles'
import {Text} from '../../LanguagesDropdown/LanguagesDropdown.styles'
import {languageMap} from '../../LanguagesDropdown/LanguagesDropdown.config'

const TopBar = ({headerData, dataLang}) => {
  const {opened, toggle, isCollapsed} = useContext(HeaderContext)
  const {language, languages, changeLanguage} = useI18next()
  const {img} = useStaticQuery(graphql`
    query {
      img: file(relativePath: {eq: "common/logo1.png"}) {
        childImageSharp {
          fluid(maxWidth: 135) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Wrapper>
      <Container>
        <LogoLink to={language !== 'de' ? `/${language}/` : '/'} aria-label="Korten Logo">
          <LogoIcon src={img} fluid={img.childImageSharp.fluid} />
        </LogoLink>
        {isCollapsed && !dataLang && (
          <LangItemsMob>
            {languages.map(l => (
              <LangItemMob
                key={l}
                onClick={() => {
                  changeLanguage(l)
                }}
                selected={l === language}>
                <Text>{languageMap[l].textShort}</Text>
              </LangItemMob>
            ))}
          </LangItemsMob>
        )}
        {!isCollapsed && (
          <LinksWrapper>
            {headerData.map(({item, subItems, itemLink, type, minWidth, border, services}) => (
              <Item
                border={border}
                services={services}
                key={item}
                data={subItems}
                title={item}
                itemLink={itemLink}
                type={type}
                minWidth={minWidth}
              />
            ))}
            {!dataLang && <LanguagesDropdown />}
          </LinksWrapper>
        )}
        {isCollapsed && (
          <OtherActionsWrapper>
            <BurgerStyled opened={opened} onClick={toggle} />
          </OtherActionsWrapper>
        )}
      </Container>
    </Wrapper>
  )
}

export default TopBar
