import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  #___gatsby {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  #gatsby-focus-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  *,
  *::before,
  *::after {
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
  }

  html {
    line-height: 1.4;
    height: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    touch-action: manipulation;
  }

  body {
    padding-top: 80px;
    margin: 0;
    min-height: 100%;
    width: 100%;
    font-family: ${({ theme }) => theme.fontFamilies.default};
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.common.white};
    display: flex;
    flex-direction: column;
    position: relative;
    font-feature-settings: 'kern';

    @media (max-width: 1199px) {
      padding-bottom: 64px;
    }

    @media (max-width: 768px) {
      padding-top: 60px;
    }
  }

  main {
    display: block;
  }

  hr {
    border-top-width: 1px;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
    font-size: 1em;
  }

  a {
    background-color: transparent;
    color: inherit;
    text-decoration: inherit;
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bold;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  img {
    border-style: none;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }

  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  textarea {
    overflow: auto;
  }

  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box;
    padding: 0;
  }

  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  [type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none !important;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  details {
    display: block;
  }

  summary {
    display: list-item;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none !important;
  }

  body,
  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  pre {
    margin: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 15px;
  }

  button {
    background: transparent;
    padding: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  ol,
  ul {
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
  }

  textarea {
    resize: vertical;
  }

  button,
  [role='button'] {
    cursor: pointer;
  }

  button::-moz-focus-inner {
    border: 0 !important;
  }

  table {
    border-collapse: collapse;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
    vertical-align: middle;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  [data-js-focus-visible] :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }

  select::-ms-expand {
    display: none;
  }

  .slick-slide, .slick-active, .slick-current {
    outline: none !important;
  }

  .slick-slider * {
    outline: none !important;
  }

  .breadcrumb {
    color: #ffffff;

    &__list {
      align-items: center;

      &__item {

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          color: #8b8b8b;
          pointer-events: none;
        }
      }
    }

    &__separator {
      max-height: 13px;
      font-size: 14px;
      color: #8b8b8b;
    }

    &__link {
      font-size: 12px;
    }
  }

  .ReactModal__Overlay {
    z-index: 9999999999999;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }
`
