import styled from 'styled-components'
import CloseIconSvg from 'images/icons/close-icon.inline.svg'
import {Item} from '../TopBar/TopBar.styles'

const mobNavFull = 500

export const MobileNavigationWrapper = styled.div`
  padding: 15px;
  position: fixed;
  width: 375px;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 300ms ease-in-out;
  background: ${({theme}) => theme.palette.common.white};
  z-index: 2;
  overflow: scroll;

  @media (max-width: ${mobNavFull}px) {
    width: 100%;
  }

  &.slide-enter {
    transform: translateX(100%);
  }

  &.slide-enter-active,
  &.slide-enter-done {
    transform: translateX(0);
  }

  &.slide-exit {
    transform: translateX(0);
  }

  &.slide-exit-active {
    transform: translateX(100%);
  }
`

export const MobileNavigationLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 60px;
`

export const LangItemsMob = styled.div`
  display: flex;
  align-items: center;
`

export const LangItemMob = styled.div(({theme, selected}) => ({
  marginRight: 25,
  fontWeight: 700,
  color: selected ? theme.palette.grey[1000] : theme.palette.grey[700],
  cursor: 'pointer',
}))

export const MobNavLink = styled(Item)`
  margin: 0;
  width: 100%;
  font-weight: 700;
  font-size: ${({theme}) => theme.fontSizes.md};
  position: relative;
  padding: 10px 0;
  justify-content: end;
  align-items: flex-start;
  display: flex;
  text-align: left;
  &:hover {
    color: ${({theme}) => theme.palette.primary.main};
  }
`

export const MobNaVDarkBg = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.2s linear;
  z-index: 1;

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active,
  &.fade-enter-done {
    opacity: 1;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
  }
`

export const TopRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px;
  outline: none;
  cursor: pointer;
  transition-duration: 150ms;

  &:hover {
    transform: scale(1.15);
  }
`

export const CloseIcon = styled(CloseIconSvg)`
  fill: currentColor;
  width: 16px;
  height: 16px;
`
