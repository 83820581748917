import styled from 'styled-components'
import {Twitter, Facebook, Whatsapp, Mail} from 'react-social-sharing'
import Text from '../Text'
import TwitterImg from '../../images/icons/twitter.svg'
import FacebookImg from '../../images/icons/facebook.svg'
import WhatsupImg from '../../images/icons/whatsup.svg'
import MailImg from '../../images/icons/mail.svg'

export const Wrapper = styled.div(({theme}) => ({
  marginLeft: 'auto',
  marginBottom: 30,
  padding: '35px 30px',
  maxWidth: 370,
  pointerEvents: 'all',
  display: 'flex',
  alignItems: 'center',
}))

export const ButtonsWrapper = styled.div(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: 190,
  width: '100%',
}))

export const Title = styled(Text)(({theme}) => ({
  fontWeight: 700,
  marginBottom: 0,
  marginRight: 50,
}))

export const TwitterButton = styled(Twitter)(({theme}) => ({
  background: `url(${TwitterImg})`,
  width: 32,
  height: 32,
  backgroundColor: 'transparent !important',
  borderColor: 'transparent !important',

  '&:hover': {
    backgroundColor: 'transparent !important',
  },
  '& span': {
    display: 'none',
  },
}))

export const FacebookButton = styled(Facebook)(({theme}) => ({
  background: `url(${FacebookImg})`,
  width: 32,
  height: 32,
  backgroundColor: 'transparent !important',
  borderColor: 'transparent !important',

  '&:hover': {
    backgroundColor: 'transparent !important',
  },
  '& span': {
    display: 'none',
  },
}))

export const MailButton = styled(Mail)(({theme}) => ({
  background: `url(${MailImg})`,
  width: 32,
  height: 32,
  backgroundColor: 'transparent !important',
  borderColor: 'transparent !important',

  '&:hover': {
    backgroundColor: 'transparent !important',
  },
  '& span': {
    display: 'none',
  },
}))

export const WhatsupButton = styled(Whatsapp)(({theme}) => ({
  background: `url(${WhatsupImg})`,
  width: 32,
  height: 32,
  backgroundColor: 'transparent !important',
  borderColor: 'transparent !important',

  '&:hover': {
    backgroundColor: 'transparent !important',
  },
  '& span': {
    display: 'none',
  },
}))
