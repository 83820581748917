import styled from 'styled-components'

export const Wrapper = styled.div(({theme}) => ({
  padding: '24px 22px',
  marginLeft: 'auto',
  marginBottom: 30,
  maxWidth: 370,
  background: theme.palette.common.white,
  pointerEvents: 'all',
}))

export const ErrorWrapper = styled.div(({theme}) => ({
  '& a': {
    display: 'none',
  },
}))
