export const headerNavigation = [
  {
    path: '/#home',
    textKey: 'header.navigation.home',
  },
  {
    path: '/#banking',
    textKey: 'header.navigation.banking',
  },
  {
    path: '/#investing',
    textKey: 'header.navigation.investing',
  },
  {
    path: '/#crypto',
    textKey: 'header.navigation.crypto',
  },
  {
    path: '/#security',
    textKey: 'header.navigation.security',
  },
]
