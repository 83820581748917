export const languageMap = {
  de: {
    iconId: 'de',
    textShort: 'DE',
    text: 'Deutsch',
  },
  en: {
    iconId: 'gb',
    textShort: 'EN',
    text: 'English',
  },
}
