import React, {useState} from 'react'
// import PropTypes from 'prop-types'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {useForm} from 'react-hook-form'
import Input from 'components/Input'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import Text from 'components/Text'
import {Link} from 'gatsby'
import * as yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {TextWrapper, Wrapper} from './FormAssessment.styles'
import Button from '../Button'
import {CloseIcon} from '../ModalFull/ModalFull.styles'
import {ErrorWrapper} from '../FormSubscribe/FormSubscribe.styles'

const schema = yup.object().shape({
  email: yup.string().email().required(),
  enquiry: yup.string().required(),
  full_name: yup.string().required(),
  phone_number: yup.number().positive().integer().required(),
})

const FormAssessment = ({children, modal, func, ...rest}) => {
  const {t} = useTranslation()
  const {register, handleSubmit, errors} = useForm({
    resolver: yupResolver(schema),
  })
  const [resultState, setResultState] = useState('')
  const onSubmit = async data => {
    try {
      const req = await fetch('https://dev.laurus.agency/korten/initialAssessment', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      const response = await req.json()
      setResultState(response)
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Wrapper modal={modal} {...rest}>
      <TextWrapper>
        <Text
          content={t('forms.assessment.title')}
          size="titleXs"
          mb={16}
          uppercase
          textAlign="center"
        />
      </TextWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          textarea
          name="enquiry"
          label={t('forms.assessment.labels.concern')}
          reference={register({required: true})}
          er={errors}
          borderFull
          uppercase
          paddingLeft={10}
        />
        {errors.enquiry && (
          <Text content={t('forms.requiredMessage')} size="xs" color="error" mb={10} />
        )}
        <Input
          type="text"
          name="full_name"
          label={t('forms.assessment.labels.fullName')}
          reference={register({required: true})}
          uppercase
        />
        {errors.full_name && (
          <Text content={t('forms.requiredMessage')} size="xs" color="error" mb={10} />
        )}
        <Input
          type="email"
          name="email"
          label={t('forms.assessment.labels.email')}
          reference={register({required: true})}
          uppercase
        />
        {errors.email && (
          <Text content={t('forms.incorrectEmailMessage')} size="xs" color="error" mb={10} />
        )}
        <Input
          type="number"
          name="phone_number"
          label={t('forms.assessment.labels.phone')}
          reference={register({required: true})}
          uppercase
        />
        {errors.phone_number && (
          <Text content={t('forms.requiredMessage')} size="xs" color="error" mb={10} />
        )}
        <Input
          type="checkbox"
          name="callback"
          reference={register}
          label={t('forms.assessment.labels.checkbox')}
          checkbox
          uppercase
        />
        <Text
          content={t('forms.assessment.privacyLink')}
          component={Link}
          to="/privacy-policy"
          size="xs"
          mb={11}
          underlined
          onClick={func}
        />
        <Text content={t('forms.assessment.disclaimer')} size="xs" mb={10} />
        <div className="clear">
          <Button
            text={t('forms.assessment.btnSubmit')}
            w100
            variant="green"
            uppercase
            type="submit"
            id="mc-embedded-subscribe"
            name="subscribe"
          />
        </div>
        {resultState.success === true && (
          <Text content={t('forms.assessment.successMessage')} mt={10} color="primary.green" />
        )}
        {resultState.success === false && (
          <ErrorWrapper>
            <Text content={t('forms.assessment.errorMessage')} mt={10} color="error" />
          </ErrorWrapper>
        )}
      </form>
    </Wrapper>
  )
}

FormAssessment.propTypes = {}
FormAssessment.defaultProps = {}

export default FormAssessment
