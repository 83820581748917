import styled from 'styled-components'
import img from 'images/lawyer-test.png'

export const Wrapper = styled.div(({theme, mAuto, textAlign, modal, boxShadow}) => ({
  marginBottom: mAuto ? 0 : 30,
  marginLeft: modal ? 'initial' : 'auto',
  marginRight: mAuto ? 'auto' : 0,
  padding: '23px 25px',
  maxWidth: 370,
  background: theme.palette.common.white,
  pointerEvents: 'all',
  textAlign,
  boxShadow: boxShadow ? `0px 2px 15px rgba(0, 0, 0, 0.196487)` : 'none',
}))

export const TextWrapper = styled.div(({theme, mAuto, textAlign, boxShadow}) => ({
  [theme.breakpoints.down('laptopS')]: {
    '& p': {
      textAlign: 'left',
    },
  },
}))
