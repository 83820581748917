import React, {useEffect, useRef} from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import Text from 'components/Text'
import {Wrapper} from './Trustpilot.styles'
import ContentContainer from '../ContentContainer'
import {graphql} from "gatsby";

const Trustpilot = ({children, ...rest}) => {
  const {t} = useTranslation()
  const ref = useRef(null)
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (typeof window !== 'undefined' && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])
  return (
    <Wrapper id="trustpilot">
      <ContentContainer>
        <Text content={t('sections.trustpilot.title')} mb={10} size="titleXs" uppercase />
        <Text content={t('sections.trustpilot.subtitle')} mb={30} size="md" />
        <div
          ref={ref}
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="5419b6a8b0d04a076446a9ad"
          data-businessunit-id="5fb4dfee9a033e000108409f"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="light">
          <a
            href="https://www.trustpilot.com/review/korten-ag.de"
            target="_blank"
            rel="noreferrer noopener">
            Trustpilot
          </a>
        </div>
      </ContentContainer>
    </Wrapper>
  )
}

Trustpilot.propTypes = {}
Trustpilot.defaultProps = {}

export default Trustpilot
