import React, {useRef} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {collapseWidth, Wrapper} from './Header.styles'
import TopBar from './TopBar'
import SideBar from './SideBar'
import {HeaderContextProvider} from './HeaderContext'

const Header = ({children, dataLang, ...rest}) => {
  const navRef = useRef(null)
  const {lawyers, services} = useStaticQuery(graphql`
    query {
      lawyers: allStrapiLawyer(sort: {order: ASC, fields: lawyer_order}) {
        edges {
          node {
            name
            page_url
          }
        }
      }
      services: allStrapiService(sort: {order: ASC, fields: service_order}) {
        edges {
          node {
            name {
              de
              en
            }
            page_url
          }
        }
      }
    }
  `)
  const servicesNamesLinks = services.edges
  // servicesNamesLinks.unshift({
  //   node: {
  //     name: {de: 'Alle Rechtsbereiche', en: 'All legal areas'},
  //     page_url: '',
  //   },
  // })
  const lawyersNamesLinks = lawyers.edges
  // lawyersNamesLinks.unshift({
  //   node: {
  //     name: 'navigation.lawyers.allLawyers',
  //     page_url: '',
  //   },
  // })
  const headerData = [
    {
      item: 'navigation.titles.services',
      services: true,
      type: 'div',
      minWidth: 350,
      subItems: servicesNamesLinks,
    },
    {
      item: 'navigation.titles.lawyers',
      services: false,
      type: 'div',
      minWidth: 220,
      subItems: lawyersNamesLinks,
    },
    {
      item: 'navigation.titles.news',
      services: false,
      itemLink: 'blogs',
      bordernone: true,
      type: '',
      subItems: [],
    },
    {
      item: 'navigation.titles.magazin',
      services: false,
      itemLink: 'kmagazin',
      bordernone: true,
      type: '',
      subItems: [],
    },
    {
      item: 'navigation.titles.client',
      services: 'client',
      border: true,
      type: 'div',
      mobOpened: true,
      minWidth: 195,
      subItems: [
        {
          node: {
            name: 'navigation.client.aboutUs',
            page_url: '/about-us',
          },
        },
        {
          node: {
            name: 'navigation.client.remunerationPolicy',
            page_url: '/remuneration-policy',
          },
        },
        {
          node: {
            name: 'navigation.client.contact',
            page_url: '/contact',
          },
        },
      ],
    },
  ]
  return (
    <Wrapper ref={navRef} {...rest}>
      <HeaderContextProvider navRef={navRef} collapseWidth={collapseWidth}>
        <TopBar dataLang={dataLang} headerData={headerData} />
        <SideBar headerData={headerData} />
      </HeaderContextProvider>
    </Wrapper>
  )
}

export default Header
