import React, {useState} from 'react'
// import PropTypes from 'prop-types'
import {ContactsInfoWrapper, Wrapper} from './GeneralContacts.styles'
import GeneralContactsNav from '../GeneralContactsNav'
import GeneralContactsHamburgInfo from '../GeneralContactsHamburgInfo'
import GeneralContactsMunchenInfo from '../GeneralContactsMunchenInfo'
import GeneralContactsGottingenInfo from '../GeneralContactsGottingenInfo'
import VideoConferenceIcons from '../VideoConferenceIcons'
import CommonContactsText from '../CommonContactsText'

const GeneralContacts = ({children, floating, ...rest}) => {
  const [tab, setTab] = useState(0)
  return (
    <Wrapper floating={floating} {...rest} className="GeneralContacts">
      <GeneralContactsNav tab={tab} setTab={setTab} />
      <ContactsInfoWrapper>
        {tab === 0 && <GeneralContactsHamburgInfo />}
        {tab === 1 && <GeneralContactsMunchenInfo />}
        {tab === 2 && <GeneralContactsGottingenInfo />}
      </ContactsInfoWrapper>
      <CommonContactsText />
      <VideoConferenceIcons />
    </Wrapper>
  )
}

GeneralContacts.propTypes = {}
GeneralContacts.defaultProps = {}

export default GeneralContacts
