import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {NavItem, Wrapper} from './GeneralContactsNav.styles'

const GeneralContactsNav = ({children, tab, setTab, ...rest}) => {
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      {/*{t('generalContacts.navItems', {returnObjects: true, defaultValue: []})?.map((txt, index) => (*/}
      {/*  <NavItem key={txt} onClick={() => setTab(index)} active={tab === index}>*/}
      {/*    {txt}*/}
      {/*  </NavItem>*/}
      {/*))}*/}
    </Wrapper>
  )
}

GeneralContactsNav.propTypes = {}
GeneralContactsNav.defaultProps = {}

export default GeneralContactsNav
