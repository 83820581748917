import React from 'react'
import styled from 'styled-components'

export const btnSizes = {
  def: ({theme}) => ({
    fontSize: theme.fontSizes.def,
    // borderRadius: theme.radii.default,
    ...theme.responsiveProperty({
      cssProperty: 'height',
      min: 54,
      max: 54,
      unit: 'px',
      breakpoints: [400],
    }),
  }),
  sm: ({theme}) => ({
    // borderRadius: theme.radii.default,
    height: 40,
  }),
}

export const btnVariants = {
  black: ({theme}) => ({
    background: `linear-gradient(210.55deg, #353A40 -3.02%, #121416 93.08%)`,
    color: theme.palette.primary.contrastText,
  }),
  blackOutline: ({theme}) => ({
    background: `transparent`,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  }),

  green: ({theme}) => ({
    background: theme.palette.primary.green,
    color: theme.palette.common.white,
  }),
}

export const IconStartWrapper = styled.div({
  marginRight: '0.7em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const IconEndWrapper = styled.div({
  marginLeft: '0.7em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const ContentLoadingWrapper = styled.div`
  opacity: 0;
`

export const ButtonStyled = styled(
  ({w100, size, variant, minWidth, isLoading, component: Component = 'button', ...props}) => (
    <Component {...props} />
  )
)(({theme, size, variant, minWidth, w100, disabled, uppercase}) => ({
  position: 'relative',
  padding: '0 10px',
  fontWeight: 600,
  cursor: 'pointer',
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transitionDuration: theme.transitions.duration.short,
  fontFamily: theme.fontFamilies.default,
  textTransform: uppercase ? 'uppercase' : 'none',

  ...btnSizes[size]({theme}),
  ...btnVariants[variant]({theme}),
  fill: 'currentColor',

  minWidth,
  width: w100 ? '100%' : null,
  maxWidth: w100 ? null : 'max-content',

  '&:hover': {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.35)',
  },

  '&:active': {
    transform: 'scale(0.97)',
  },

  ...(disabled && {
    opacity: 0.6,
    cursor: 'not-allowed',
    transform: 'none',
    filter: 'none',
    IconStartWrapper: {
      opacity: 0.7,
    },
    IconEndWrapper: {
      opacity: 0.7,
    },
  }),
}))
