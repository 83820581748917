export const normalizePath = path => {
  if (!path) return null

  let res = path
  if (!path.endsWith(`/`) && path.indexOf('#') < 0) {
    res = `${path}/`
  }

  if (!path.startsWith(`/`) && path.indexOf('#') < 0) {
    res = `/${path}`
  }

  return res
}

export function hasOwnPropertyCall(obj, key) {
  if (!obj) return false
  return Object.prototype.hasOwnProperty.call(obj, key)
}
