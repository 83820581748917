import React, {useContext, useState} from 'react'
import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {Wrapper, DropdownIcon, Title, ItemBorder} from './DropdownItem.styles'
import DropdownMenu from '../DropdownMenu'
import {collapseWidth} from '../Header/Header.styles'
import useWindowSize from '../../hooks/helpers/useWindowSize'
import HeaderContext from '../Header/HeaderContext'

const DropdownItem = ({
  children,
  data,
  title,
  itemLink,
  type,
  minWidth,
  border,
  services,
  mobOpened,
  bordernone,
  posrel,
  ...rest
}) => {
  const [dropdown, setDropdown] = useState(false)
  const {language, changeLanguage} = useI18next()
  const {t} = useTranslation()

  const {close} = useContext(HeaderContext)

  const size = useWindowSize()

  const onMouseEnterHandler = () => {
    if (size.width < collapseWidth) return

    setDropdown(true)
  }

  const onMouseLeaveHandler = () => {
    if (size.width < collapseWidth) return

    setDropdown(false)
  }

  const onClickHandler = () => {
    if (size.width > collapseWidth) return

    setDropdown(prevState => !prevState)
  }

  const clickOnly = () => {
    setDropdown(prevState => !prevState)
  }

  const clickItemTitle = () => {
    close()
  }

  return (
    <Wrapper
      {...rest}
      to={`${language !== 'de' ? `/${language}` : ''}/${itemLink}`}
      as={type}
      posrel={posrel}
      onMouseEnter={!posrel ? onMouseEnterHandler : null}
      onMouseLeave={!posrel ? onMouseLeaveHandler : null}
      /* eslint-disable-next-line no-nested-ternary */
      onClick={itemLink ? clickItemTitle : !posrel ? onClickHandler : clickOnly}
      bordernone={bordernone}>
      <Title bordernone={bordernone} posrel={posrel} itemLink={itemLink}>
        {t(title)} {!itemLink && <DropdownIcon posrel={posrel} />}
        {border && <ItemBorder minWidth={minWidth} />}
      </Title>
      {dropdown && !mobOpened && !itemLink && (
        <DropdownMenu posrel={posrel} data={data} services={services} minWidth={minWidth} />
      )}
      {mobOpened && <DropdownMenu data={data} services={services} minWidth={minWidth} />}
    </Wrapper>
  )
}

DropdownItem.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  itemLink: PropTypes.string,
  type: PropTypes.string,
  minWidth: PropTypes.number,
  posrel: PropTypes.bool,
}
DropdownItem.defaultProps = {}

export default DropdownItem
