import styled from 'styled-components'

export const padding = 15
export const maxWidth = 1170

const ContentContainer = styled.div`
  max-width: ${padding * 2 + maxWidth}px;
  padding-left: ${padding}px;
  padding-right: ${padding}px;
  width: 100%;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({withMt}) => withMt && '35px'};
  margin-bottom: ${({withMb}) => withMb && '35px'};
`

export default ContentContainer
