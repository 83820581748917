import styled from 'styled-components'

export const Wrapper = styled.div(({theme, checkbox, flexStart}) => ({
  marginBottom: 15,
  display: checkbox ? 'flex' : 'block',
  alignItems: flexStart ? 'flex-start' : 'center',
}))

export const InputStyled = styled.input(({theme, borderFull, checkbox, paddingLeft, mt}) => ({
  marginTop: mt || 0,
  marginRight: checkbox ? 9 : 0,
  paddingLeft: paddingLeft || 0,
  width: checkbox ? 16 : '100%',
  // eslint-disable-next-line no-nested-ternary
  height: borderFull ? 132 : checkbox ? 16 : 37,
  flex: checkbox ? '0 0 16px' : '',
  outline: 'none',
  background: 'none',
  fontSize: 'inherit',
  color: 'inherit',
  boxSizing: 'border-box',
  border: borderFull ? `1px solid ${theme.palette.grey[800]}` : 'none',
  borderBottom: `1px solid ${theme.palette.grey[800]}`,
  borderRadius: 0,
  boxShadow: 'none',

  '&::placeholder': {
    color: theme.palette.input.placeholder,
    textAlign: 'left',
  },

  '&:focus::placeholder': {
    color: 'transparent',
  },
}))

export const TextAreaStyled = styled.textarea(({theme, borderFull, checkbox, paddingLeft, mt}) => ({
  marginTop: mt || 0,
  marginRight: checkbox ? 9 : 0,
  paddingLeft: paddingLeft || 0,
  width: checkbox ? 16 : '100%',
  // eslint-disable-next-line no-nested-ternary
  height: borderFull ? 132 : checkbox ? 16 : 37,
  flex: checkbox ? '0 0 16px' : '',
  outline: 'none',
  background: 'none',
  fontSize: 'inherit',
  color: 'inherit',
  boxSizing: 'border-box',
  border: borderFull ? `1px solid ${theme.palette.grey[800]}` : 'none',
  borderBottom: `1px solid ${theme.palette.grey[800]}`,
  borderRadius: 0,
  boxShadow: 'none',

  '&::placeholder': {
    color: theme.palette.input.placeholder,
    textAlign: 'left',
  },

  '&:focus::placeholder': {
    color: 'transparent',
  },
}))