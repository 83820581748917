import React from 'react'
// import PropTypes from 'prop-types'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import {
  Wrapper,
  Title,
  TwitterButton,
  FacebookButton,
  WhatsupButton,
  MailButton,
  ButtonsWrapper,
} from './Share.styles'

const Share = ({children, location, ...rest}) => {
  const {t} = useTranslation()
  return (
    <Wrapper {...rest}>
      <Title content={t('share.title')} />
      <ButtonsWrapper>
        <FacebookButton link={typeof window !== 'undefined' ? window.location.href : null} />
        <TwitterButton link={typeof window !== 'undefined' ? window.location.href : null} />
        <WhatsupButton link={typeof window !== 'undefined' ? window.location.href : null} />
        <MailButton link={typeof window !== 'undefined' ? window.location.href : null} />
      </ButtonsWrapper>
    </Wrapper>
  )
}

Share.propTypes = {}
Share.defaultProps = {}

export default Share
