import styled from 'styled-components'
import Text from '../Text'
import DropdownItem from '../DropdownItem'
import {collapseWidth} from '../Header/Header.styles'

export const Wrapper = styled.div(({theme}) => ({
  marginLeft: 'auto',
  marginBottom: 30,
  maxWidth: 370,
  width: '100%',
  zIndex: 100,
  pointerEvents: 'all',
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.196487)',
  [theme.breakpoints.down('laptopS')]: {
    margin: '0 auto 30px',
    maxWidth: 420,
  },
}))

export const Title = styled(Text)(({theme}) => ({
  textTransform: 'uppercase',
  padding: '14px 10px 12px 22px',
  fontWeight: 'bold',
  margin: 0,
}))

export const Item = styled(DropdownItem).attrs(() => ({}))`
  position: relative;
  display: flex;
  cursor: pointer;
  color: ${({theme}) => theme.palette.primary.light};
  transition-duration: 150ms;
  padding: 13px 23px;
  border-top: 1px solid #cacaca;

  &.item--active {
    color: ${({theme}) => theme.palette.primary.main};
    font-weight: 800;
  }
  &:hover {
    color: ${({theme}) => theme.palette.primary.main};
  }
`
