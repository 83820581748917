import resolvePath from 'object-resolve-path'
import {responsiveProperty} from '@material-ui/core/styles/cssUtils'
import {hasOwnPropertyCall} from 'utils'
import palette from './palette'
import {fontSizes, fontFamilies} from './typography'
import {radii} from './other'
import breakpoints from './breakpoints'
import transitions from './transitions'

const resolve = (obj, path) => {
  try {
    return resolvePath(obj, path)
  } catch (err) {
    return path
  }
}

const resolveColor = (obj, path, getMain = false) => {
  try {
    const res = resolvePath(obj, `palette.${path}`)
    if (getMain && hasOwnPropertyCall(res, 'main')) return res.main
    return res
  } catch (err) {
    return path
  }
}

const theme = {
  // colors
  palette,

  // typography
  fontSizes,
  fontFamilies,

  // border radius
  radii,

  // media queries
  breakpoints,

  // animations
  transitions,
}

theme.resolve = path => resolve(theme, path)
theme.resolveColor = (path, getMain) => resolveColor(theme, path, getMain)
theme.responsiveProperty = responsiveProperty

export default theme
